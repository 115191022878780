<template>
  <div class="home"></div>
</template>

<style></style>

<script>
import router from "../router";

export default {
  name: "home",
  methods: {
    redirect() {
      if (this.$store.getters.isLoggedIn) {
        router.push("/dashboard");
      } else {
        router.push("/auth/sign_in");
      }
    },
  },
  mounted() {
    this.redirect();
  },
};
</script>
